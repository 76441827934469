import dayjs from 'dayjs'
import UserInfoManager from 'public/src/services/UserInfoManager'

const LOCALSTORAGE_KEY = 'kidTag_key'

// 获取唯一标识[memberid]
function getSoleKey () {
  const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'RECOMMENDPOP/GETKEY' })
  return memberId || gbCommonInfo?.SiteUID
}

/**
 * kidTag 本地缓存
 * @returns  {
 *  [memberid_pathname]: {
 *    inster: time, // 插入时间
 *    popover: true | false // 是否已经展示popover
 *  }
 * }
 */
function getKidsTagLocalStorage () {  
  const cache = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY) ?? '{}')
  return cache
}

function setKidsTagLocalStorage (cache) {  
  localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(cache))
}


export function getIsShowKidsTagPopoverByCache () {
  if (typeof window == 'undefined') return false

  const key = getSoleKey()
  const allCacheData = getKidsTagLocalStorage()
  const cache = allCacheData[key] ?? {}
  allCacheData[key] = cache
  if (!cache.popover) {
    cache.popover = true
    setKidsTagLocalStorage(allCacheData)
    return true
  }

  return false
}/**
 * 更新kids在标签云的位置
 * @param {*} tagAllList 
 * @param {*} kidsTags 
 */
export function updateTagKidsPosition (tagAllList, kidsTags) {
  const key = getSoleKey()
  const childLength = kidsTags?.[0]?.child_list?.length || 0
  const allCacheData = getKidsTagLocalStorage()
  const cache = allCacheData[key] ?? {}
  allCacheData[key] = cache

  // 首次进入
  if (!cache.inster) {
    cache.inster = Date.now()
    setKidsTagLocalStorage(allCacheData)
    return [...tagAllList]
  }

  // 有小孩信息
  if (childLength) return

  // 无小孩信息
  const nowDay = dayjs()
  const cacheDay = dayjs(cache.inster)
  // 超过7天
  if (nowDay.diff(cacheDay, 'day') >= 7) {
    cache.inster = Date.now()
    setKidsTagLocalStorage(allCacheData)
    return
  }
  
  // 超过24h - 标签云最后一位
  if (nowDay.diff(cacheDay, 'day') >= 1) {
    tagAllList.push(tagAllList.shift())
    return 
  } 

  // 24h内 - 标签云第一位
}

// 获取 kids 对应的值
export const getKidValue = item => {
  const { sex } = item
  const nowDay = dayjs()
  const birthday = dayjs(item.birthday, 'YY-MM')
  const totalMonth = nowDay.diff(birthday, 'month')

  if (totalMonth <= 36) {
    return sex == 1 ? '600060714' : '600060717'
  } else if (totalMonth <= 84) {
    return sex == 1 ? '600060719' : '600060720'
  } else if (totalMonth <= 144) {
    return sex == 1 ? '600060724' : '600060725'
  } else if (totalMonth < 192) {
    return sex == 1 ? '600060731' : '600060732'
  }
  
  return ''
}

// 判断当前标签是否为 kids 标签【】
export const isFilterByKids = id => {
  return [
    '600060714', 
    '600060717',
    '600060719', 
    '600060720',
    '600060724', 
    '600060725',
    '600060731', 
    '600060732'
  ].includes(id)
}

const DEFAULT_CHILD_KEY = 'defaultChild'
const DEFAULT_CHILD_PARAMS = { 
  default_child_id: '', 
  default_child_sex: '', 
  default_child_birthday: ''
}

// 校验是否为默认儿童页面【真实/选品/搜索/店铺/shein picks】
const verifyDefaultKidsPage = (pathname) => {
  const patterns = [
    /\/.+-c-\d+\.html$/, // 真实
    /\/.+-sc-[A-Za-z0-9]+\.html$/, // 选品
    /pdsearch\/.+/, // 搜索
    /\/.*([a-z]+-picks|romwe-[a-z]+|promo-discount)\.html$/, // shein picks
    /store\/home$/, // 店铺
    /.+-store-\d{10}\.html$/ // 店铺
  ]

  return patterns.some(pattern => pattern.test(pathname || window.location.pathname))
}

// 获取默认选中的儿童信息档案
export const getDefaultKidParams = (pathname) => {
  if (
    typeof window == 'undefined'
    || !verifyDefaultKidsPage(pathname)
  ) return DEFAULT_CHILD_PARAMS

  const key = getSoleKey()
  const allCacheData = getKidsTagLocalStorage()
  return allCacheData[key]?.[DEFAULT_CHILD_KEY] || DEFAULT_CHILD_PARAMS
}

// 设置默认选中的儿童信息档案
export const setDefaultKidParams = (kid = {}) => {
  if (typeof window == 'undefined') return

  const key = getSoleKey()
  const allCacheData = getKidsTagLocalStorage()
  const cache = allCacheData[key] ?? {}
  allCacheData[key] = cache
  
  cache[DEFAULT_CHILD_KEY] = {
    default_child_id: kid?.child_id || '', 
    default_child_sex: kid?.sex || '', 
    default_child_birthday: kid?.birthday || ''
  }

  setKidsTagLocalStorage(allCacheData)
}

// 清除默认选中的儿童信息档案-走默认kids筛选后无商品返回
export const clearDefaultKidParams = (queryParams = {}) => { 
  Object.keys(DEFAULT_CHILD_PARAMS).forEach(key => { 
    queryParams.hasOwnProperty(key) && delete queryParams[key]
  })
}

// 根据返回数据判断是否命中默认选中的儿童信息档案链路【返回defaultChild && defaultChild存在儿童信息列表中】
export const isHitDefaultChild = ({ kidsTag = {}, defaultChild = {}, }) => {
  if (!defaultChild?.child_id || !kidsTag?.child_list?.length) return false

  return !!kidsTag?.child_list.find(item => item.child_id == defaultChild.child_id)
}
