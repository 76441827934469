import schttp from 'public/src/services/schttp'

export const uploadImgApi = async (formData) => {
  if (!formData) {
    return Promise.resolve({
      code: -1,
      msg: 'miss params'
    })
  }
  try {
    return await schttp({
      method: 'POST',
      url: '/uploadShareImg',
      data: formData,
      // cache: false,
      // processData: false,
      // contentType: false,
      timeout: 60000,
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

export const uploadImgBffApi = async (file) => {
  if (!file) {
    return Promise.resolve({
      code: -1,
      msg: 'miss params'
    })
  }
  try {
    return await schttp({
      method: 'POST',
      url: '/setting/upload_image',
      data: {
        image: file
      },
      timeout: 60000,
      useBffApi: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}


export const transformBase64BffApi = async ({
  file,
  url
}) => {
  try {
    if (!/^https/.test(url) && /^\/\//.test(url) && url) {
      url = 'https:' + url // 拼接协议
    }
    return await schttp({
      method: 'POST',
      url: '/category-api/recommend/image_replacement_base64',
      data: {
        image: file,
        img_url: url
      },
      timeout: 60000,
      useBffApi: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}
