// wiki.pageId=481264374
export const searchWordsGlobalAnalysis = new class {
  #KEY = 'searchWordsGlobalAnalysis'
  TYPE_DESC_MAP = { // pageId=481264374
    2: 'EditSearch',
    3: 'DefaultSearch',
    4: 'SuggestionSearch',
    5: 'RecentSearch',
    6: 'HotSearch',
    7: 'SuggestionSearchCategory',
    8: 'SuggestionSearchFeedback',
    9: 'HotSearchPic',
    10: 'ListFlow',
    11: 'SearchTrend',
    12: 'ListSearch',
    15: 'CompoundSearch',
    16: 'ClickGoodsSearch',
    18: 'SuggestionAttri', //  17:列表后端走搜索服务
    19: 'CorrectOriginalWord', // 纠错前的原词
    20: 'AdsSearch', //  广告搜索词类型
    22: 'ListNavSearch', // 列表图文导航
  }
  // 跟踪ABC重定向
  refreshRedir(search_redir) {
    const data = this.get()
    if (data.d || !search_redir) return
    data.d = 1
    sessionStorage.setItem(this.#KEY, JSON.stringify(data))
  }
  get() {
    let data = sessionStorage.getItem(this.#KEY)
    try {
      data = JSON.parse(data) || {}
    } catch (error) {
      data = {}
    }
    return data
  }
  set(obj) {
    if (Object.prototype.toString.call(obj) === '[object Object]') {
      const parent_page = (obj.parent_page || SaPageInfo?.page_name)
        ?.split('_')
        .map(_ => {
          if (!_) return ''
          return _[0].toLocaleUpperCase() + _.slice(1)
        })
        .join('')
      if (parent_page) {
        // 搜索页来源额外增加描述
        obj.parent_page = parent_page === 'PageSearch'
          ? parent_page + (+obj.result_count || +SaPageInfo.page_param.result_count || +SaPageInfo.page_param.rec_count ? 'Result' : 'NoResult')
          : parent_page
      } else delete obj.parent_page
      sessionStorage.setItem(this.#KEY, JSON.stringify(obj))
    }
  }
  getPageFrom(resultTypeValue = 0) {
    const { result_type, result_word, d, parent_page } = this.get()

    let typeDesc = ''
    if (resultTypeValue > 0) {
      typeDesc = this.TYPE_DESC_MAP[resultTypeValue]
    } else {
      typeDesc = this.TYPE_DESC_MAP[result_type]
    }


    return `s1\`${typeDesc || ''}\`${encodeURIComponent(result_word) || ''}\`_fb\`d${d || 0}\`${parent_page || 'PageOthers'}`
  }
}()
